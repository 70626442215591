* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body, html {
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    background-color: #f3f3f3;
}

.link-prev {
    text-decoration-line: underline !important;
    text-decoration-style: dotted !important;
    text-decoration-color: #1F73D3 !important;
}

.page-grid-2-columns {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 10px;
    -webkit-column-gap: 10px;
    column-gap: 10px;
}

.page-grid-2-columns .box {
    width: 100%;
    margin: 10px 0;
    display: inline-block;
    box-sizing: border-box;
}

.dica-objeto-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    grid-gap: 1em;
}

.link{
    color: #3c7ddd;
    cursor: pointer;
}

.primeiraLetraMaiuscula:first-letter {
    text-transform: capitalize;
}

.filter-checkbox-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
  